<script lang="ts">
    import { onMount } from 'svelte';
    import type { Attachment, Post, User } from '../../interfaces/types';

    export let attachment: Attachment;
    export let post: Post;
    export let currentUser: User;
    export let hasVideoAttachment: boolean;

    let videoElement: HTMLVideoElement;

    const isConverting =
        currentUser &&
        ((post.user_id === currentUser.id && post.status === 0 && hasVideoAttachment) ||
            (currentUser.role_id === 1 && post.status === 0 && hasVideoAttachment));

    const password = new Date(attachment.created_at).toISOString().replace(/[-:T]/g, '').slice(0, 14);

    function renderThevideoForApple(attachment: Attachment, password: string) {
        // TODO: Implement the actual function logic
    }

    onMount(() => {
        renderThevideoForApple(attachment, password);
    });
</script>

<video
    bind:this="{videoElement}"
    id="my-video-{attachment.id}"
    width="640"
    poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
    controls
>
</video>

<style>
    video {
        max-width: 100%;
        height: auto;
    }
</style>
